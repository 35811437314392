//--debugLog----------------------------------------//
import  debugLog  from '../../modules/rule/debugLog';

//--State = 状態オブジェクト(MVCでいうところのモデル)--//
//--Action = 変更オブジェクト(変更を記録する履歴のイメージ)--//
//--ActionCreater = アクションを生成する関数--//
//--Reducer = 状態の変化を決定するための関数--//
//--Store = Status/Action/Reducerを結びつけるオブジェクト--//

type Actions = (
  ReturnType<typeof funcPopupHead__okAction> |
  ReturnType<typeof funcPopupHead__ngAction> |
  ReturnType<typeof funcPopupHead__resetAction>
)

export type State = {
  popupHead__text: string;
  popupHead__ok: string;
  popupHead__ng: string;
}

//--Operation--------------------------------------------------------------//
export const funcPopupHead__okOperation = (popupHead__text: string, popupHead__time: number) => {
  return async (dispatch, getState) => {

    const state = getState();

    //--onに設定(P opupHead0に渡り、最終的にCSSに入る)--//
    dispatch(funcPopupHead__okAction(popupHead__text, 'on'));

    setTimeout(() => {
      //--offに設定(P opupHead0に渡り、最終的にCSSに入る)--//
      dispatch(funcPopupHead__okAction(popupHead__text, 'off'));
      debugLog('1秒経過しました | ');
    }, popupHead__time)
  }
}
export const funcPopupHead__ngOperation = (popupHead__text: string, popupHead__time: number) => {
  return async (dispatch, getState) => {
    const state = getState();

    //--onに設定(P opupHead0に渡り、最終的にCSSに入る)--//
    dispatch(funcPopupHead__ngAction(popupHead__text, 'on'));

    setTimeout(() => {
      //--offに設定(P opupHead0に渡り、最終的にCSSに入る)--//
      dispatch(funcPopupHead__ngAction(popupHead__text, 'off'));
      debugLog('1秒経過しました | ');
    }, popupHead__time)
  }
}

//--Action--------------------------------------------------------------//
//--Action Type--------------------------------//
export const POPUPHEAD__OK = 'POPUPHEAD__OK' as const
export const POPUPHEAD__NG = 'POPUPHEAD__NG' as const
export const POPUPHEAD__RESET = 'POPUPHEAD__RESET' as const

//--Actions Main--------------------------------//
export const funcPopupHead__okAction = (popupHead__text: string, popupHead__ok: string) => ({
  type: POPUPHEAD__OK,
  payload: {
    popupHead__text,
    popupHead__ok,
  }
})
export const funcPopupHead__ngAction = (popupHead__text: string, popupHead__ng: string) => ({
  type: POPUPHEAD__NG,
  payload: {
    popupHead__text,
    popupHead__ng,
  }
})
export const funcPopupHead__resetAction = () => ({
  type: POPUPHEAD__RESET
})


//--Reducer--------------------------------------------------------------//
const initialState: State = {
  popupHead__text: '',
  popupHead__ok: 'off',
  popupHead__ng: 'off',
}

export default (state: State = initialState, action: Actions) => {

  switch (action.type) {
    //--POPUPHEAD__OK------------------------------//
    case POPUPHEAD__OK: {
      return {
        popupHead__text: action.payload.popupHead__text,
        popupHead__ok: action.payload.popupHead__ok,
      }
    }
    //--POPUPHEAD__NG------------------------------//
    case POPUPHEAD__NG: {
      return {
        popupHead__text: action.payload.popupHead__text,
        popupHead__ng: action.payload.popupHead__ng,
      }
    }
    //--POPUPHEAD__RESET------------------------------//
    case POPUPHEAD__RESET:
      return initialState
    default:
      return state;
  }
}