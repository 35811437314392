import { combineReducers, createStore, applyMiddleware } from 'redux';
import popupHead__reducer from '../../redux/var1/reducer';
import thunk from 'redux-thunk';


//const def__combineReducers = combineReducers<{ first: ReturnType<typeof popupHead__reducer> }>({ first: popupHead__reducer })
const def__combineReducers = combineReducers<{ first: ReturnType<typeof popupHead__reducer> }>({ first: popupHead__reducer })

export type State = ReturnType<typeof def__combineReducers>

//--createStore(reducer)ストアを作成--//
//省略文:export default () => createStore(reducer)
const def__store = () => {
  return (
    createStore(
      def__combineReducers,
      applyMiddleware(thunk)
    )
  );
}
export default def__store;
