//--コンソールログ true:表示 false:非表示------------------------------//
//--注意！！デプロイ時(本番)では必ず非表示にする--//

//--【本番向け】コンソールログなし--//
const debugDisplay = false;

//--【開発向け】コンソールログあり--//
//const debugDisplay = true;

const debugLog = (debugDisplay) ? console.log.bind(console) : () => { };

export default debugLog;